* {
    box-sizing: border-box;
}

/* Create three unequal columns that floats next to each other */
.column {
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    height: 70vh;
}
  
.left{
    width: 5%;
    background-color:#aaa;
    flex-direction: column;
    align-items: stretch;
}

.left > div {
    width: auto;
    font-size: 1.5rem;
    align-content: center;
    display: block;
    block-size: max-content;
}

.middle {
    width: 75%;
    background-color:white;
    overflow: auto;
}

.right{
    width: 20%;
    background-color:#ccc;
}

.row{
    width: 90vw;
    min-width: 90vw;
    margin: auto;
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
  }